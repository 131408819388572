import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export let banner_data = [
  {
    id: 1,
    img: "img/banner/Sekem - banner.jpg",
  },
  // {
  //   id: 2,
  //   img: "img/banner/NZFootballLogo.png",
  // },
  // Agrega más elementos a la matriz si es necesario
];

function MerchPartnerComponent() {
  return (
    <div className="merch-partner-container">
      <Carousel autoPlay infiniteLoop showThumbs={false}>
        {banner_data.map((item) => (
          <div className="banner-img" key={item.id}>
            <img src={item.img} className="merch-image" alt={`Merch ${item.id}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

function BannerHome() {
  return (
    <div className="">
      <MerchPartnerComponent />
    </div>
  );
}

export default BannerHome;
