import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BannerHome from "./bannerHome";
import Brand from "./brand";
import Catalogue from "./catalogue";
import Stockist from "./stockist";
import AboutUs from './aboutUs';
import Partner from "./partners";
import { Fade } from "react-awesome-reveal";



function Home() {
  return (
    <div className="">
      <BannerHome />
      <Brand />
      <AboutUs />
      <Fade triggerOnce delay={500}>
      <Stockist />
      </Fade>
      <Catalogue />
      <Partner />
    </div>
  );
}

export default Home;
