import React from 'react';


const Stockist = () => {
  return (
    <section className="stockist" id="stockist"> 
 <h1>SEKEM STOCKIST</h1> 
<p>Welcome to our network of trusted stockists that bring our products closer to you. We take pride in partnering with reputable retailers who share our passion for quality and excellence. These stockists are your go-to destinations for finding our latest collections, ensuring you have easy access to the products that inspire and elevate your lifestyle.</p> 
    <div className="contenedor-imagenes">
      <div className="fila">
        <img src="img/stockist/stockist-01.png" alt="Imagen 1" className="imagenStockist" />
        <img src="img/stockist/stockist-02.png" alt="Imagen 2" className="imagenStockist" />
        <img src="img/stockist/stockist-03.png" alt="Imagen 3" className="imagenStockist" />
        <img src="img/stockist/stockist-04.png" alt="Imagen 4" className="imagenStockist" />
        <img src="img/stockist/stockist-05.png" alt="Imagen 5" className="imagenStockist" />
        <img src="img/stockist/stockist-06.png" alt="Imagen 6" className="imagenStockist" />
        <img src="img/stockist/stockist-07.png" alt="Imagen 7" className="imagenStockist" />
        <img src="img/stockist/stockist-08.png" alt="Imagen 8" className="imagenStockist" />
        <img src="img/stockist/stockist-09.png" alt="Imagen 9" className="imagenStockist" />
      </div>
    </div>

    </section>
  );
};

export default Stockist;






