import React from 'react';
import './App.css';
import { Fade, Slide, slide } from "react-awesome-reveal";


function ImagenConTitulo(props) {
  return (
    <div className="imagen-con-titulo">
      <img src={props.imagenUrl} alt={props.titulo} />
      <h2 className="titulo-catalogue">
        <a href={props.pdfUrl} download={props.pdfFileName}>
          {props.titulo}
        </a>
      </h2>
    </div>
  );
}

function Catalogue() {
  const catalogItems = [
    {
      imagenUrl: "img/Catalogue/officialMerch.png",
      pdfUrl: "img/Catalogue/Sekem-NZ 2023.pdf",
      pdfFileName: "Sekem.pdf",
      titulo: "Download Catalogue"
    },
    {
      imagenUrl: "img/Catalogue/fff.png",
      pdfUrl: "img/Catalogue/Catalogue 2023_V2.pdf",
      pdfFileName: "Ford-Football-Ferns.pdf",
      titulo: "Download Catalogue"
    }
  ];

  return (
    <section className="Catalogue" id="catalogue">
      <Fade triggerOnce cascade delay={300} >
      <h1>SEKEM CATALOGUES</h1> 
      
      <h4 className='white'>Discover Our Latest Catalogues for Sekem and Ford Football Ferns</h4>
      <p>Download our catalogues and view the latest collections of licensed sports merchandise, Sports gear, supporters’ apparel and accessories. Whether you are an avid Sports fan, a dedicated athlete or sports enthusiast, our catalogues provide an in-depth look into the exceptional products we have to offer, that embody the spirit of sportsmanship and excellence.</p> 
      <div className="imagenes-contenedor">
        {catalogItems.map((item, index) => (
          <ImagenConTitulo
            key={index}
            imagenUrl={item.imagenUrl}
            pdfUrl={item.pdfUrl}
            pdfFileName={item.pdfFileName}
            titulo={item.titulo}
          />
        ))}
      </div>
      </Fade>
    </section>
  );
}

export default Catalogue;
