import React from "react";
import { Fade } from "react-awesome-reveal";

function AboutUs() {
    return <section className="aboutUs" id="aboutUs">
        <Fade triggerOnce cascade delay={300} >
                {/* <h1>SEKEM ABOUT US</h1>  */}
                <p>Kookaburra Burley Sekem is Australia’s leading supplier of licensed sports merchandise and specializes in licensed accessories, luggage, headwear and replica and supporter apparel, distributing through all bona fide sports retailers in NZ.</p>
                {/* <p>Sekem is the New Zealand wholesale distributor for Burley Sekem, Australia's leading supplier of licensed sports apparel. Sekem specialises in sports branded headgear, luggage, accessories, licensed replica and supporters' apparel, distributing and supplying sports stores throughout New Zealand. 
<br />Please <a href="#contact">contact us</a> for more information or to enquire on setting up a retail account.
</p>  */}
                </Fade>
        </section>
}

export default AboutUs;