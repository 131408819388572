import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route, Link, Switch, NavLink } from "react-router-dom";
import Brand from './brand';
import Catalogue from './catalogue';
import Stockist from './stockist';
import AboutUs from './aboutUs';
import Partner from './partners';
import Header from './header';
import Footer from './footer';
import Home from './home';


function App() {
  return (
   <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/brand" element={<Brand />}/>
        <Route path="/catalogue" element={<Catalogue />} />
        <Route path="/stockist" element={<Stockist />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/partners" element={<Partner />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
