import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const images = [
    { id: 1, src: "img/brand/AllBlacks.png", alt: "All Blacks"},
    { id: 3, src: "img/brand/SuperRugby.png", alt: "Super Rugby"},
    { id: 4, src: "img/brand/NZRugbyLeague.png", alt: "New Zealand Rugby League"},
    { id: 5, src: "img/brand/KiwiFerns.png", alt: "Kiwi Ferns"},
    { id: 6, src: "img/brand/NZFootball.png", alt: "New Zealand Football"},
    { id: 7, src: "img/brand/AmpolState.png", alt: "Ampol State od Origin"},
    { id: 8, src: "img/brand/LeagueMen.png", alt: "League Men"},
    { id: 9, src: "img/brand/SilverFerns.png", alt: "Silver Ferns"},
    { id: 10, src: "img/brand/NRL.png", alt: "NRL"},
];
const Brand = () => {
  return (
    <div className="collage-section" id="brand">
      <Splide className="splide"
        options={{
          type: "loop",
          perPage: 6,
          focus: "center",
          autoplay: true,
          interval: 2000,
          height: "550px",
marginRight:"100px",
          breakpoints: {
            1300: {
              perPage: 5, 
            },
            950: {
              perPage: 4,
            },
            850: {
              perPage: 3,
              height: "450px",
            },
          },
        }}
      >
        {images.map((image, index) => (
          <SplideSlide key={index}>
            <img src={image.src} alt={image.alt} className="collage-image" />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default Brand;