import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

function App() {
  return (
    <div>
        <div className="logoNavbar">
            <img src="img/sekem.png" className="logo" alt="Sekem" style={{ width: '300px' }} />
        </div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto custom-font">    
            <Nav.Link className='nav3' id='txtblanco' href="#brand">BRAND</Nav.Link>
            <Nav.Link className='nav3' id='txtblanco' href="#aboutUs">ABOUT US</Nav.Link>
            <Nav.Link className='nav3' id='txtblanco' href="#stockist">STOCKIST</Nav.Link>
              <Nav.Link className='nav3' id='txtblanco' href="#catalogue">CATALOGUE</Nav.Link>
              <Nav.Link className='nav3' id='txtblanco' href="#partner">PARTNERS</Nav.Link>
              <Nav.Link className='nav3' id='txtblanco' href="#contact">CONTACT</Nav.Link>
              
            </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default App;



