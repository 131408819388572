import React, { useState, useRef } from 'react';
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

export const Footer = () => {
    const [formData, setFormData] = useState({
      first_name: '',
      email_id: '',
      message: '',
    });
   
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const recaptchaRef = useRef(null);


    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
      };
    
  
    const handleSubmit = (e) => {
      e.preventDefault();

      if (!recaptchaValue) {
        console.error('Please complete the reCAPTCHA.');
        return;
      }
  

      const serviceId = 'service_mxk20ep';
      const templateId = 'template_xamsely';
      const userId = 'aImxa9UKM7m_PvF29';
  

      emailjs.send(serviceId, templateId, formData, userId)
        .then((response) => {
          console.log('Email sent successfully:', response);
          setFormData({
            first_name: '',
            email_id: '',
            message: '',
          });
          setRecaptchaValue(null);
          recaptchaRef.current.reset();
        })
        
        .catch((error) => {
          console.error('Error sending email:', error);
        });
    };




  return (
    <section className='footer' id="contact">
        <div>
            <h1>CONTACT US</h1> 
            <p>Please don't hesitate to send us a message if you have any questions. We are here to help and would be delighted to assist you with any inquiries you may have.</p> 
        </div>
    <div className="footer" id="contacto">
      <MDBFooter className='text-body text-center text-lg-left '>
        <MDBContainer className='p-4' style={{ maxWidth: '700px' }}>
        <MDBRow>
  <MDBCol lg='5' md='12' className='mb-4 mb-md-0' id="infoFooter">
    <div className="infocourseFooter" id="infoFooterTitulo2"></div>
    <div className='contactInfo'>
      <div className='logo-footer'>
        <img className='logoFooter' src="/img/sekemWhite.png" alt="Logo" style={{ width: '170px' }} />
      </div>
      <div className='info-email'>
        <h4 style={{ marginBottom: '0.3rem' }}>Email to us</h4>
        <h6 style={{ marginBottom: '0.2rem' }}>sales@sekem.co.nz</h6>
      </div>
    </div>
  </MDBCol>



            <MDBCol lg='7' md='12' className='mb-4 mb-md-0'>
              <div className='registerForm'>
                <div className='formApply'>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4" as={Col} controlId="validationCustom01">
                      <Form.Control
                        required
                        type="text"
                        placeholder="Name"
                        feedback="Please write your first name."
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please write your name
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" as={Col} controlId="validationCustomUsername">
                      <InputGroup hasValidation>
                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          aria-describedby="inputGroupPrepend"
                          required
                          name="email_id"
                          value={formData.email_id}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please choose an email.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-4" as={Col} controlId="message">
                      <InputGroup hasValidation>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          type="text"
                          placeholder="Message"
                          aria-describedby="inputGroupPrepend"
                          name="message"
                          required
                          value={formData.message}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please write a message.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group
                        className="mb-3 d-flex justify-content-end"
                        controlId="checkBox1"
                        style={{ color: "white" }}
                        >
                          {/* este recapcha es para localhost */}
                            {/* <ReCAPTCHA  sitekey='6Le0MU8oAAAAAM4zQ8AMbN6ylaqWJP8w-3MwrqMA' onChange={handleRecaptchaChange} /> */}
                            {/* este recaptcha es para el sitio www.sekem.co.nz */}
                            <ReCAPTCHA  sitekey='6Lcnf6woAAAAAPt_k4OfEZPirNBn0tBX8PH2nQtD' onChange={handleRecaptchaChange} /> 
                            

                    </Form.Group>

                    <div className='btnForm' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button id="btn" type="submit">Send</Button>
                    </div>
                  </Form>
                </div>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBCol lg='11' md='11' className='mb-4 mb-md-4' id="links-footer">
  <br />
  <ul className='list-unstyled d-flex justify-content-center flex-wrap links-container text-center' id="listFooter">
  <li className="link-item">
    <a href='#brand' style={{ textDecoration: 'none' }} className='linkFooter'>
      BRAND
    </a>
  </li>

  <li className="link-item">
    <a href='#aboutUs' style={{ textDecoration: 'none' }} className='linkFooter'>
      ABOUT US
    </a>
  </li>

  <li className="link-item">
    <a href='#stockist' style={{ textDecoration: 'none' }} className='linkFooter'>
      STOCKIST
    </a>
  </li>

  <li className="link-item">
    <a href='#catalogue' style={{ textDecoration: 'none' }} className='linkFooter'>
      CATALOGUE
    </a>
  </li>


  <li className="link-item">
    <a href='#partner' style={{ textDecoration: 'none' }} className='linkFooter'>
      PARTNERS
    </a>
  </li>
</ul>

</MDBCol>


        </MDBContainer>
      </MDBFooter>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(31,40,87)', color: "white" }}>
      Website crafted  <a href="https://www.natalilarroza.com" target="_blank" style={{ color: 'white', textDecoration: 'none', fontWeight: "bold" }}>by Natali Larroza</a>  |  &copy; {new Date().getFullYear()} Sekem
          </div>
    </div>
    </section>
  );
}

export default Footer;

